<template>
  <section class="backgroundColor">
    <v-row>
      <v-col cols="12">
        <DataTable :headers="headers" :items="listaEtapas" :tiene_paginacion="false">
          <template v-slot:[`item.fecha_hora_inicio`]="{ item }">
            {{ item.fecha_hora_inicio !== null ? moment(item.fecha_hora_inicio).format('DD/MM/YYYY hh:mm A') : '' }}
          </template>
          <template v-slot:[`item.fecha_hora_fin`]="{ item }">
            {{ item.fecha_hora_fin !== null ? moment(item.fecha_hora_fin).format('DD/MM/YYYY hh:mm A') : '' }}
          </template>
        </DataTable>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import DataTable from "@/components/DataTableComponent.vue";

export default {
  name: "EtapaProcesoCompraComponent",
  props: ['id'],
  components: {
    DataTable,
  },
  data: () => ({
    headers: [
      {
        text: "Etapas",
        align: "start",
        sortable: true,
        value: "nombre",
      },
      {
        text: "Fecha de inicio",
        align: "start",
        sortable: true,
        value: "fecha_hora_inicio",
      },
      {
        text: "Fecha de fin",
        align: "start",
        sortable: true,
        value: "fecha_hora_fin",
      },
      {
        text: "Observación",
        align: "start",
        sortable: true,
        value: "observacion",
      },
    ],
  }),
  computed: {
    ...mapState("paacProveedor",[
      "listaEtapas",
    ]),
  },
  methods: {
    ...mapActions("paacProveedor", [
      "getEtapas",
    ]),
  },
  async created(){
    if(this.id !== null){
      await this.getEtapas(this.id)
    }
  },
};
</script>

<style scoped>
.backgroundColor {
  background-color: #f4f7fd;
}
</style>
