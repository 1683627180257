<template>
  <section class="pb-10">
    <!-- Mensaje que se mostrara a los participantes que perdieron -->
    <div v-if="!ganador">
      <p>
        Muchas gracias por participar en el proceso, puedes seguir participando
        en otro proceso disponible dentro de comprasal.
      </p>
    </div>

    <!-- Información que se le mostrara al ganador -->
    <div v-else>
      <!-- informacion -->
      <v-row class="mb-5">
        <v-col cols="12">
          <p>
            Deberá acercarse a {{ nombre_institución }}, al area de UCP para
            celebrar el contrato.
          </p>
          <p>
            Deberá de identificarse con sus credenciales al momento de ingresa a
            las instalaciones
          </p>

          <p>Deberá de presentar los siguientes documentos:</p>
          <ul>
            <li>DUI y NIT de la empresa Original</li>
            <li>DUI y NIT del representante legal</li>
          </ul>
        </v-col>
      </v-row>

      <!-- filtros -->
      <!-- <v-row>
        <v-col cols="4">
          <v-select
            v-model="filters.id_obs"
            :items="obs_options"
            item-value="id"
            item-text="nombre"
            label="OBS"
            outlined
            @change="obtainListProveedores(filters)"
            clearable
          />
        </v-col>
        <v-col cols="4">
          <v-select
            v-model="filters.id_sub_proceso_obs"
            :items="especificacion_options"
            label="Especificación"
            item-value="id"
            item-text="especificacion"
            outlined
            @change="obtainListProveedores(filters)"
            clearable
          />
        </v-col>
      </v-row> -->

      <!-- table -->
      <!-- <v-row>
        <v-col cols="12">
          <data-table-component
            v-models:select="perPageModel"
            v-models:pagina="pageModel"
            :total_registros="total_rows"
            @paginar="paginar"
            :headers="headers"
            :items="items"
          >
            <template v-slot:[`item.total`]="{ item }">
              {{
                Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(item.total)
              }}
            </template>

            <template v-slot:[`item.evaluacion_legal`]="{ item }">
              <v-icon v-if="item.evaluacion_legal" color="green">
                mdi-check-circle
              </v-icon>
              <v-icon v-else color="red">mdi-close-circle</v-icon>
            </template>

            <template v-slot:[`item.acciones`]="{ item }">
              <v-btn icon @click="mostrarEvaluacion(item.id)">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>
          </data-table-component>
        </v-col>
      </v-row> -->

      <!-- Modal para ver la información -->
      <app-dialog
        :max-width="1200"
        v-model="showModal"
        title="Oferta presentada"
      >
        <template #content>
          <div>
            <v-row>
              <v-col cols="12" sm="5">
                <v-row>
                  <v-col cols="12">
                    <span>Nombre del proveedor</span>
                    <p class="font-weight-black">
                      {{ ofertaSeleccionada.proveedor }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <span>OBS</span>
                    <p class="font-weight-black">
                      {{ ofertaSeleccionada.nombre_obs }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <span>Articulo</span>
                    <p class="font-weight-black">
                      {{ ofertaSeleccionada.articulo }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <span>Unidad de medida</span>
                    <p class="font-weight-black">
                      {{ ofertaSeleccionada.presentacion }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <span>Especificación</span>
                    <p class="font-weight-black">
                      {{ ofertaSeleccionada.especificacion }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" sm="7">
                <v-card elevation="5">
                  <v-card-text>
                    <v-card-title style="color: #404a7a">
                      Formulario de oferta
                    </v-card-title>
                    <v-row>
                      <v-col cols="12">
                        <!-- TODO: hacerlo descargable -->
                        <v-text-field
                          prepend-icon=""
                          outlined
                          label="Oferta"
                          prepend-inner-icon="mdi-paperclip"
                          :value="ofertaSeleccionada.documento"
                          readonly
                        >
                          <template #append>
                            <v-btn
                              class="mt-n2"
                              color="primary"
                              icon
                              @click="
                                obtainLinkDocument(
                                  ofertaSeleccionada.ruta_documento
                                )
                              "
                            >
                              <v-icon> mdi-download </v-icon>
                            </v-btn>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <money-text-field-component
                          ref="mtfPrecioUnitario"
                          label="Precio unitario"
                          :value="ofertaSeleccionada.precio_unitario"
                          readonly
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          outlined
                          label="Cantidad"
                          min="0"
                          type="number"
                          :value="ofertaSeleccionada.cantidad"
                          readonly
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="5">
                        <money-text-field-component
                          ref="mtfTotal"
                          label="Total oferta"
                          :value="ofertaSeleccionada.total"
                          readonly
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row class="justify-center">
              <v-col cols="1" class="d-flex flex-column align-center">
                <p class="text-h5">
                  {{ ofertaSeleccionada.evaluacion_tecnica }}
                </p>
                <p class="text-center">Evaluación técnica</p>
              </v-col>
              <v-col cols="1" class="d-flex flex-column align-center">
                <p class="text-h5">
                  {{ ofertaSeleccionada.evaluacion_financiera }}
                </p>
                <p class="text-center">Evaluación financiera</p>
              </v-col>
              <v-col cols="1" class="d-flex flex-column align-center">
                <p class="text-h5">
                  <v-icon
                    v-if="ofertaSeleccionada.evaluacion_legal"
                    color="green"
                    >mdi-check-circle</v-icon
                  >
                  <v-icon v-else color="error">mdi-close-circle</v-icon>
                </p>
                <p class="text-center">Evaluación legal</p>
              </v-col>
              <v-col cols="2">
                <v-card color="secondary" class="px-3 pt-2 pb-1">
                  <div class="d-flex flex-column align-center custom-card">
                    <p class="text-h5 white--text">
                      {{ ofertaSeleccionada.porcentaje_otorgado || 0 }}
                    </p>
                    <p class="text-center white--text">Porcentaje otorgado</p>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="2">
                <v-card color="secondary" class="px-3 pt-2 pb-1">
                  <div class="d-flex flex-column align-center custom-card">
                    <p class="text-h5 white--text">
                      {{ cantidadA }}
                    </p>
                    <p class="text-center white--text">Cantidad ganada</p>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="3">
                <v-card color="secondary" class="px-3 pt-2 pb-1">
                  <div class="d-flex flex-column align-center custom-card">
                    <p class="text-h5 white--text">
                      {{ cantidadB }}
                    </p>
                    <p class="text-center white--text">Cantidad ganada</p>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </template>
        <template #actions>
          <div class="mt-3">
            <v-btn
              color="secondary"
              @click="(showModal = false), (ofertaSeleccionada = {})"
              class="mr-4"
              outlined
            >
              Cerrar
            </v-btn>
          </div>
        </template>
      </app-dialog>
    </div>
  </section>
</template>
<script>
import MoneyTextFieldComponent from "@/components/MoneyTextFieldComponent.vue";
import DataTableComponent from "@/components/DataTableComponent";
import { mapState } from "vuex";
export default {
  name: "EtapaContratacionComponent",
  components: {
    DataTableComponent,
    MoneyTextFieldComponent,
  },
  data() {
    return {
      ganador: false,
      nombre_institución: "Hospital Nacional San Juan de Dios",
      ofertaSeleccionada: {},
      // Mostrar el modal
      showModal: false,
      // Filtros
      filters: {
        id_obs: null,
        id_sub_proceso_obs: null,
      },
      filterPage: {
        page: 1,
        per_page: 10,
      },
      total_rows: null,
      document: null,
      obs_options: [],
      especificacion_options: [],
      proveedor_options: [],
      items: [],
      headers: [
        {
          text: "Especificación",
          value: "especificacion",
          sortable: true,
          width: "50%",
        },
        { text: "Oferta($)", value: "total", sortable: true, align: "end" },
        {
          text: "Evaluación financiera",
          value: "evaluacion_financiera",
          sortable: true,
          align: "end",
        },
        {
          text: "Evaluación técnica",
          value: "evaluacion_tecnica",
          sortable: true,
          align: "end",
        },
        {
          text: "Evaluación legal",
          value: "evaluacion_legal",
          sortable: true,
          align: "center",
        },
        {
          text: "% Otorgado",
          value: "porcentaje_otorgado",
          sortable: true,
          align: "end",
        },
        { text: "Acciones", align: "center", value: "acciones" },
      ],
    };
  },

  computed: {
    ...mapState("paacProveedor", ["id_proceso_compra"]),
    cantidadA() {
      const porcentaje = Number(this.ofertaSeleccionada.porcentaje_otorgado);
      const cantidad = Number(this.ofertaSeleccionada.cantidad);
      let cantidadA = Number((cantidad / 100) * porcentaje);
      return Intl.NumberFormat("en-US", {}).format(cantidadA);
    },
    cantidadB() {
      let cantidadB =
        Number(this.cantidadA) *
        Number(this.ofertaSeleccionada.precio_unitario);
      return Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(cantidadB);
    },
    // Models para data table
    pageModel: {
      get() {
        return this.filterPage.page;
      },
      set(value) {
        this.filterPage.page = value;
      },
    },
    perPageModel: {
      get() {
        return this.filterPage.per_page;
      },
      set(value) {
        this.filterPage.per_page = value;
      },
    },
  },

  methods: {
    mostrarEvaluacion(id) {
      this.showModal = true;
      this.obtainParticipacion(id);
    },
    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.filterPage.page = pagina;
      this.filterPage.per_page = cantidad_por_pagina;
      this.obtainListProveedores();
    },
    setPaginationData(payload) {
      this.filterPage.page = Number(payload.page);
      this.filterPage.per_page = Number(payload.per_page);
      this.total_rows = Number(payload.total_rows);
    },
    async obtainLinkDocument(route) {
      try {
        const response =
          await this.services.Paac.downloadDocumentoProcesoCompra(route);
        if (response) {
          const blob = new Blob([response.data], {
            type: "application/pdf",
          });

          const file = new File([blob], "documento.pdf", {
            type: "application/pdf",
          });

          // Descargar el archivo
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(file);
          link.download = route.toString();
          link.click();

          this.document = file;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async obtainParticipacion(id) {
      const { data } = await this.services.Paac.getParticOferta(id);
      if (data) {
        this.ofertaSeleccionada = data;
      }
    },
    async obtainListProveedores(filters = {}) {
      const idUsuario = this.$store.state.userInfo.user.id;
      const idProcesoCompra = this.$route.params.id_proceso_compra;
      filters = {
        ...this.filterPage,
        ...filters,
      };
      const response = await this.services.Paac.getParticpaciones(
        idUsuario,
        idProcesoCompra,
        filters
      );
      if (response) {
        if (!response.data) {
          this.ganador = false;
        } else {
          this.ganador = true;
          this.setPaginationData(response.headers);
          this.items = response.data;
        }
      }
    },
    async fetchOBS() {
      const response = await this.services.Paac.getOBSPart();
      if (response) {
        this.obs_options = response.data;
      }
    },
    async fetchEspecificaciones() {
      const response = await this.services.Paac.getSubProcPart();
      if (response) {
        this.especificacion_options = response.data;
      }
    },
    async fetchProveedores() {
      const response = await this.services.Paac.getProveedores();
      if (response) {
        this.proveedor_options = response.data;
      }
    },
  },
  watch: {
    "ofertaSeleccionada.precio_unitario": function (value) {
      if (value) {
        this.$refs.mtfPrecioUnitario.updateValue(value);
      } else {
        this.$refs.mtfPrecioUnitario.clear();
      }
    },
    "ofertaSeleccionada.total": function (value) {
      if (value) {
        this.$refs.mtfTotal.updateValue(value);
      } else {
        this.$refs.mtfTotal.clear();
      }
    },
  },
  created() {
    this.obtainListProveedores();
    this.fetchOBS();
    this.fetchEspecificaciones();
  },
};
</script>
