<template>
  <section class="backgroundColor">
    <v-row>
      <v-col cols="12">
        <DataTable
          :headers="headers"
          :items="listaDocumentos"
          :show_actions="true"
          :actions="actions"
          @documentVersion="documentVersion"
          :tiene_paginacion="false"
        >
          <template v-slot:[`item.fecha_carga`]="{ item }">
            {{ moment(item.fecha_carga).format('DD/MM/YYYY')}}
          </template>
        </DataTable>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import DataTable from "@/components/DataTableComponent.vue";

export default {
  name: "DocumentosProcesoCompraComponent",
  props: ['id'],
  components: {
    DataTable,
  },
  data: () => ({
    headers: [
      {
        text: "Tipo de documento",
        align: "start",
        sortable: true,
        value: "nombre_tipo_documento",
      },
      {
        text: "Fecha de última modificación",
        align: "start",
        sortable: true,
        value: "fecha_carga",
      },
      {
        text: "Versiones",
        align: "start",
        sortable: true,
        value: "actions",
      },
    ],
    actions: [
      {
        tooltip: {
          text: "Version de Documento",
          color: "blueGrayMinsal",
        },
        icon: {
          color: "secondary",
          name: "mdi-magnify",
        },
        eventName: "documentVersion",
      },
    ],
  }),
  computed: {
    ...mapState("paacProveedor",[
      "listaDocumentos",
    ]),
  },
  methods: {
    ...mapActions("paacProveedor", [
      "getDocumentos",
    ]),
    documentVersion(item) {
    },
  },
  async created(){
    await this.getDocumentos(this.id)
  },
};
</script>

<style scoped>
.backgroundColor {
  background-color: #f4f7fd;
}
</style>
