<template>
  <section>
    <p class="mb-15">
      Durante este proceso se revisará cada recurso cargado por los proveedores,
      de haber cambios en las evaluaciones se notificará. <br> Sus recursos
      presentados son:
    </p>
  </section>
</template>
<script>
export default {};
</script>
<style lang="scss"></style>
