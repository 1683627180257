<template>
  <section>
    <app-countdown :dateNow="moment()" :nextDay="moment(fechaFinal)" />
    <v-row>
      <v-col cols="12" sm="6" class="mt-5">
        <v-file-input
          placeholder="Oferta general"
          outlined
          prepend-inner-icon="mdi-paperclip"
          accept="application/pdf"
          :prepend-icon="null"
          v-model="OfertaGeneral"
          clearable
          @input="$v.OfertaGeneral.$touch()"
          @blur="$v.OfertaGeneral.$touch()"
          :error-messages="ofertaGeneralErrors"
        >
        </v-file-input>
      </v-col>
      <v-col cols="12" sm="6" class="mt-8">
        <v-btn @click="saveOferta" dark color="secondary"> Guardar </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" class="mt-5">
        <v-text-field
          append-icon="mdi-magnify"
          outlined
          label="Buscar OBS"
          v-model="params.filtros.obs"
          @input="buscar()"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <DataTable
          :headers="headers"
          :items="listaRecepcionOfertas"
          :total_registros="params.filtros.total_rows"
          v-models:pagina="params.filtros.page"
          v-models:select="params.filtros.per_page"
          @paginar="paginate"
        >
          <template v-slot:[`item.cantidad`]="{ item }">
            {{ item.ProcesoObs[0].total_obs }}
          </template>
          <template v-slot:[`item.unidadMedida`]="{ item }">
            {{ item.ProcesoObs[0].subprocesos_obs[0]?.presentacion?.nombre }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn outlined @click="verArticulo(item)"> Ver articulos</v-btn>
          </template>
        </DataTable>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import DataTable from "@/components/DataTableComponent.vue";
import { helpers, required } from "vuelidate/lib/validators";
import LegacyValidations from "@/utils/legacy-validations";
import { mapActions, mapMutations, mapState } from "vuex";

function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}

export default {
  name: "RecepcionOferta",
  components: {
    DataTable,
  },

  data: () => ({
    OfertaGeneral: null,
    params: {
      procesoCompraId: null,
      filtros: {
        obs: null,
        page: 1,
        per_page: 10,
        total_rows: 1,
      },
    },
    timer: null,
    headers: [
      {
        text: "Codigo",
        align: "start",
        sortable: true,
        value: "codigo",
      },
      {
        text: "OBS",
        width: "50%",
        align: "start",
        sortable: true,
        value: "nombre",
      },
      {
        text: "Cantidad",
        align: "start",
        sortable: true,
        value: "cantidad",
      },
      {
        text: "Unidad de medida",
        align: "start",
        sortable: true,
        value: "unidadMedida",
      },
      {
        text: "Acciones",
        align: "center",
        sortable: true,
        value: "actions",
      },
    ],
  }),
  validations: {
    OfertaGeneral: {
      required,
      fileNameValid,
      fileSizeValid: LegacyValidations.maxFileSizeRule(),
    },
  },

  computed: {
    ...mapState("etapaRecepcionOfertas", ["listaRecepcionOfertas"]),
    ...mapState("etapaRecepcionOfertasUaci", ["fechaFinal"]),
    ofertaGeneralErrors() {
      const errors = [];
      if (!this.$v.OfertaGeneral.$dirty) return errors;
      !this.$v.OfertaGeneral.required && errors.push("El archivo es requerido");
      !this.$v.OfertaGeneral.fileNameValid &&
        errors.push("El archivo debe ser un PDF");
      !this.$v.OfertaGeneral.fileSizeValid && LegacyValidations.pushToErrorsArray(errors);
      return errors;
    },
  },

  methods: {
    verArticulo(item) {
      this.$router.push({
        name: "ver-obs-paac",
        params: {
          id_proceso_compra: this.$route.params.id_proceso_compra,
          id_obs: item.ProcesoObs[0].id,
        },
      });
    },

    buscar() {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
        await this.obtenerObs();
      }, 500);
    },

    async obtenerObs() {
      let response = await this.services.Paac.getEtapaActual(
        this.$route.params.id_proceso_compra,
        this.params.filtros
      );
      if (response.status === "200") {
        this.setPaginacion(response.headers);
      }
    },

    setPaginacion(payload) {
      this.params.filtros.page = Number(payload.page);
      this.params.filtros.per_page = Number(payload.per_page);
      this.params.filtros.total_rows = Number(payload.total_rows);
    },

    async paginate(params) {
      const { cantidad_por_pagina, pagina } = params;
      (this.params.filtros.page = pagina),
        (this.params.filtros.per_page = cantidad_por_pagina);
      await this.obtenerObs();
    },
    async saveOferta() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        let formData = new FormData();
        formData.append("oferta_general", this.OfertaGeneral);

        const { status } =
          await this.services.Paac.postOfertaGeneral(
            this.$route.params.id_proceso_compra,
            formData
          );
        if (status == 200) {
          this.temporalAlert({
            show: true,
            message: "Documento agregado exitosamente",
            type: "success",
          });
        }
      }
    },
  },
};
</script>

<style></style>
