<template>
  <section>
    <v-row class="align-center">
      <v-col cols="6" md="1" sm="2">
        <v-img
          class="ml-4"
          width="100px"
          src="@/assets/img/log-gob.png"
        ></v-img>
      </v-col>
      <v-col cols="12" md="8" sm="8">
        <h3 class="ml-5">{{ shortText }}</h3>
      </v-col>
      <!-- Validamos si el proveedor aun puede participar en la etapa -->
      <div v-if="infoGeneral.etapa != null && infoGeneral.etapa.id_etapa <= 4">
        <v-col cols="12" md="3" v-if="!participacionProveedor">
          <v-btn
            v-if="!showBtnDecline"
            :block="$vuetify.breakpoint.xs ? true : false"
            color="error"
            outlined
            class="ma-2 text-capitalize"
            v-text="'Rechazar'"
            @click="confirmDecline = true"
          />
          <v-btn
            v-if="!showBtnDecline"
            :block="$vuetify.breakpoint.xs ? true : false"
            color="secondary"
            class="ma-2 bgWhite--text text-capitalize"
            @click="value = true"
          >
            Participar
          </v-btn>
        </v-col>
      </div>
    </v-row>
    <v-row class="ml-5">
      <v-col cols="12">
        <app-tabs :contenido="contenido" :items="items" :id="Number(id)" />
      </v-col>
      <v-col cols="12">
        <v-btn color="secondary" outlined :to="'/proveedor-paac'">Volver</v-btn>
      </v-col>
    </v-row>
    <AppDialog
      v-model="value"
      :showClose="false"
      :classButtons="'d-flex justify-space-around'"
      title="¿Desea aplicar al proceso?"
      :maxWidth="500"
    >
      <template #content>
        <section>
          <p>
            De confirmarse la siguiente acción, los cambios no serán reversibles.
          </p>
        </section>
      </template>
      <template #actions>
        <div>
          <v-btn color="secondary" outlined @click="value = false" class="mr-4">
            Cancelar
          </v-btn>
          <v-btn
            color="secondary"
            @click="participarProceso()"
            class="mr-4 bgWhite--text"
          >
            Aplicar
          </v-btn>
        </div>
      </template>
    </AppDialog>
    <modal-confirmar
      :show="confirmDecline"
      title="¿Desea rechazar la invitación?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      @close="confirmDecline = false"
      @confirm="declineProveedor"
    />
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import GeneralCompra from "./components/GeneralProcesoCompraComponent.vue";
import DocumentoCompra from "./components/DocumentosProcesoCompraComponent.vue";
import EtapaCompra from "./components/EtapaProcesoCompraComponent.vue";
import EtapaActualContainer from "./components/etapas/EtapaActualContainerComponent.vue";
import modalConfirmar from "@/components/ConfirmationDialogComponent.vue";
export default {
  name: "ConfiguracionProcesoPaac",
  components: {
    GeneralCompra,
    DocumentoCompra,
    EtapaCompra,
    EtapaActualContainer,
    modalConfirmar,
  },
  data: () => ({
    showBtnDecline: false,
    confirmDecline: false,
    participacionProveedor: false,
    id: null,
    value: false,
  }),
  methods: {
    ...mapActions("paacProveedor", ["participar", "verificarParticipacion"]),
    ...mapActions("procesoCompra", ["getInfoGeneralProcesoCompra"]),
    async participarProceso() {
      const response = await this.participar(this.id);
      if (response.status === 200) {
        this.temporalAlert({
          show: true,
          message: response.data.message,
          type: "success",
        });
        this.participacionProveedor = true;
      } else {
        this.temporalAlert({
          show: true,
          message: response.message,
          type: "error",
        });
      }
      this.value = false;
    },
    confirmarProceso() {
      this.contenido.map((item) => {
        if (item.hide == true) item.hide = false;
      });
      this.items.map((item) => {
        if (item.hide == true) item.hide = false;
      });
      this.value = false;
    },
    async validateDecline() {
      const { data } = await this.services.Paac.getRechazo(this.id);
      if (data) {
        this.showBtnDecline = data.proveedor_rechazado;
      }
    },
    async declineProveedor() {
      const { data } = await this.services.Paac.postRechazo(this.id);
      if (data) {
        this.temporalAlert({
          show: true,
          type: "info",
          message: data.message || "Se ha rechazado la invitación",
        });
        this.$router.push({ name: "dashboard" });
      }
    },
    async verificarParticipacionProveedor(id_proceso_compra) {
      const response = await this.verificarParticipacion(id_proceso_compra);
      if (response?.data?.participacion?.id) {
        this.participacionProveedor = true;
      }
    },
  },
  computed: {
    ...mapState("paacProveedor", ["infoGeneral"]),
    contenido() {
      return [
        {
          tab: "Etapa actual",
          content: EtapaActualContainer,
          show: false,
          hide: !this.participacionProveedor,
        },
        {
          tab: "General",
          content: GeneralCompra,
        },
        {
          tab: "Documentos del Proceso",
          content: DocumentoCompra,
        },
        {
          tab: "Etapas del Proceso",
          content: EtapaCompra,
        },
      ];
    },
    items() {
      return [
        {
          text: "Etapa actual",
          icono: "mdi-flag-triangle",
          hide: !this.participacionProveedor,
        },
        {
          text: "General",
          icono: "mdi-clipboard-text",
        },
        {
          text: "Documentos del Proceso",
          icono: "mdi-book-open",
        },
        {
          text: "Etapas del Proceso",
          icono: "mdi-format-list-numbered",
        },
      ];
    },
    shortText() {
      let text = this.infoGeneral?.nombre_proceso;

      if (this.$vuetify.breakpoint.xs) {
        return text.slice(0, 30) + "...";
      } else return text;
    },
  },
  async created() {
    if (
      this.$route.params.id_proceso_compra !== null ||
      this.$route.params.id_proceso_compra !== undefined
    ) {
      this.id = this.$route.params.id_proceso_compra;
      this.validateDecline();
      this.verificarParticipacionProveedor(this.id);

      const response = await this.getInfoGeneralProcesoCompra(this.id);
      // Si el id_seguimiento_proceso es null, entonces no se ha publicado el proceso
      // y se redirecciona a proveedor-paac
      if (response?.data?.id_seguimiento_proceso === null) {
        this.$router.push({ name: "proveedor-paac" });
      }
    }
  },
};
</script>

<style scoped></style>
