<template>
  <section class="backgroundColor">
    <v-row class="py-5">
      <v-col cols="6">
        <p class="text-h6 secondary--text mb-5">{{ titulo }}</p>
      </v-col>
      <v-col cols="3">
        <p><b>Fecha de inicio:</b> {{ fecha_inicio }}</p>
      </v-col>
      <v-col cols="3">
        <p><b>Fecha de fin:</b> {{ fecha_final }}</p>
      </v-col>
    </v-row>
    <!-- TODO: Agregar if para carga el componente actual de forma dinámica -->
    <v-row>
      <v-col cols="12">
        <BasesDelProcesoComponent v-if="idEtapa == 1" />
        <ConsultasComponent v-if="idEtapa == 2" />
        <!-- Se está reutilizando el componente que está en UCP -->
        <AdendasEnmiendasComponent v-if="idEtapa == 3" :idProceso="idProceso" />
        <RecepcionOfertaComponentP v-if="idEtapa == 4" :fecha_final="fecha_final" :idProcesoCompra="idProceso"/>
        <!-- <AperturaOfertaComponent v-if="idEtapa == 5" /> -->
        <!--El endpoint que corresponde a este 'EtapaEvaluacionComponent' tambien está con una etapa 7, se hizo la integración,
         pero se deja comentado para no perjudicar los demás procesos: -->
         
        <EtapaEvaluacionComponent v-if="idEtapa == 7"  :idProceso="idProceso"/> 

        <AperturaOfertaComponent v-if="idEtapa == 5" :idProceso="idProceso" />
        <EtapaEvaluacionComponents v-if="idEtapa == 6" />
        <!-- <EvaluacionOfertasComponent v-if="idEtapa == 7" /> -->
        <SubastaInversaComponent v-if="idEtapa == 9" />
        <Resultados v-if="idEtapa == 10 || idEtapa == 13" :idEtapa="idEtapa"/>
        <EtapaRecursosRevisionComponent v-if="idEtapa == 11" :idProceso="idProceso"/>
        <EvaluacionRecursosRevisionComponent v-if="idEtapa == 12" :idProceso="idProceso"/>
        <!-- <ResultadosFinalesComponent v-if="idEtapa == 12" /> -->
        <EtapaContratacionComponent v-if="idEtapa == 14" :idProceso="idProceso"/>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import RecepcionOfertaComponent from "./EtapaRecepcionOfertaComponent.vue";
import BasesDelProcesoComponent from "../etapas/EtapaBasesDelProcesoComponent.vue";
import ConsultasComponent from "../../../PaacUaci/components/etapas/EtapaConsultasUACIComponent.vue";
//Se está reutilizando el componente que está en UACI
import AdendasEnmiendasComponent from "../../../PaacUaci/components/etapas/EtapaAdendasEnmiendasUACIComponent.vue";
import Resultados from "../../../PaacUaci/components/etapas/EtapaResultadosPreliminaresUACIComponent.vue";
import SubastaInversaComponent from "@/views/PaacUaci/components/etapas/EtapaSubastaInversaUACIComponent.vue";
import AperturaOfertaComponent from "@/views/PaacUaci/components/etapas/EtapaAperturaOfertaUACIComponent.vue";
import EvaluacionRecursosRevisionComponent from "@/views/PaacUaci/components/etapas/EtapaEvaluacionRecursosRevisionUACIComponent.vue";
import EtapaEvaluacionComponents from "../EvaluacionComponent.vue";
// Se esta importando el componente desde PaacUaci dado que son vistas iguales
import EtapaRecursosRevisionComponent from "../../../PaacUaci/components/etapas/EtapaRecursoRevisionUACIComponent.vue";
import EtapaEvaluacionRecursosComponent from "../EtapaEvaluacionRecursosComponent.vue";
import EtapaContratacionComponent from "../EtapaContratacionComponent.vue";
import EtapaEvaluacionComponent from "./EtapaEvaluacionComponent.vue";
import { mapMutations, mapActions } from "vuex";

export default {
  name: "EtapaActualComponent",
  components: {
    RecepcionOfertaComponent,
    BasesDelProcesoComponent,
    ConsultasComponent,
    SubastaInversaComponent,
    AdendasEnmiendasComponent,
    Resultados,
    AperturaOfertaComponent,
    EtapaEvaluacionComponents,
    EvaluacionRecursosRevisionComponent,
    // Se esta importando el componente desde PaacUaci dado que son vistas iguales
    EtapaRecursosRevisionComponent,
    EtapaEvaluacionRecursosComponent,
    EtapaContratacionComponent,
    EtapaEvaluacionComponent,
  },
  data() {
    return {
      titulo: "Bases del Proceso",
      fecha_inicio: "",
      fecha_final: "",
      idEtapa: null,
      idProcesoCompra: this.$route.params.id_proceso_compra,
    };
  },

  computed: {},

  methods: {
    ...mapActions("etapaConsultasStore", [
      "setConsultas",
      "setIdProcesoCompra",
    ]),
    ...mapMutations("etapaRecepcionOfertas", ["setOfertas"]),
    ...mapMutations("etapaAperturaOfertaStore", ["setListaAperturaOferta"]),
    ...mapMutations("etapaBaseProceso", ["setListDocumentosBase"]),
    ...mapMutations("etapaSubastaInversaStore", ["setSalas"]),
    ...mapMutations("paacProveedor", ["setIdProceso"]),
    ...mapMutations("etapaRecepcionOfertasUaci", ["setFechaFinal"]),
    ...mapActions("etapaResultadosPreliminares", [
      "setIdProcesoResultadosPreliminares",
    ]),
    handlerTypeVuex(data) {
      switch (data.id_etapa) {
        case 1:
          this.setListDocumentosBase(data?.documentos);
          break;
        case 2:
          this.setConsultas(data.consultas);
          this.setIdProcesoCompra(this.idProceso);
          break;
        case 3:
          break;
        case 4:
          this.setOfertas(data?.documentos?.rows);
          this.setFechaFinal(data?.fecha_hora_fin);
          break;
        case 5:
          this.setListaAperturaOferta(data.data);
          break;
        case 6:
          break;
        case 7:
          break;
        case 8:
          break;
        case 9:
          this.setSalas(data.salas);
          break;
        case 10:
          this.setIdProcesoResultadosPreliminares(this.idProceso);
          break;
        case 11:
          break;
        case 12:
          this.setIdProcesoResultadosPreliminares(this.idProceso);
          break;
        case 13:
          this.setIdProceso(this.idProcesoCompra);
          this.setIdProcesoResultadosPreliminares(this.idProceso);
          break;
        case 14:
          this.setIdProceso(data.id);
          break;
        default:
          this.temporalAlert({
            show: true,
            type: "error",
            message:
              " No se ha encontrado la etapa, por favor contacte al administrador del sistema",
          });
          break;
      }
    },

    async obtainEtapaActual() {
      const response = await this.services.Paac.getEtapaActual(this.idProceso);
      if (response) {
        this.titulo = response.data.nombre;
        this.fecha_inicio = this.moment(response.data.fecha_hora_inicio).format(
          "DD/MM/YYYY HH:mm"
        );
        this.fecha_final = this.moment(response.data.fecha_hora_fin).format(
          "DD/MM/YYYY HH:mm"
        );
        this.idEtapa = response.data.id_etapa;
        this.handlerTypeVuex(response.data);
      }
    },
  },

  async created() {
     this.idProceso = this.$route.params.id_proceso_compra;
    await this.obtainEtapaActual();
  },
};
</script>

<style scoped>
.backgroundColor {
  background-color: #f4f7fd;
}
</style>
