<template>
  <section>
    <v-row>
      <v-col cols="12">
        <p>
          Durante este proceso el jurado realizará las evaluaciones pertinentes
          a cada proveedor por cada uno de los OBS.
        </p>

        <p>Sus ofertas presentadas son:</p>
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field label="Especificación" outlined> </v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-select label="Proveedor" outlined :items="proveedorSelect">
            </v-select>
          </v-col>
          <v-col cols="12" sm="4">
            <v-select label="OBS" :items="obsSelect" outlined> </v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <DataTable
          :headers="headers"
          :items="items"
          :show_actions="true"
          :actions="actions"
          @show="show"
        >
        </DataTable>
      </v-col>
    </v-row>
    <!-- Modal -->
    <AppDialog v-model="value" title="Oferta presentada" :maxWidth="1000">
      <template #content>
        <v-row>
          <v-col cols="12" sm="5">
            <v-row>
              <v-col cols="12">
                <span>Nombre del proveedor</span>
                <p class="font-weight-black">La ceiba</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <span>OBS</span>
                <p class="font-weight-black">14111500 Papel bond</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <span>Articulo</span>
                <p class="font-weight-black">Papel bond blanco 96%</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <span>OBS</span>
                <p class="font-weight-black">Resma</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <span>Especificación</span>
                <p class="font-weight-black">
                  Carta de 216x279 mm, compatibilidad: Copiadoras e impresoras.
                </p>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="7">
            <v-card elevation="5">
              <v-card-text>
                <v-card-title style="color:#404a7a">Formulario de oferta</v-card-title>
                <v-row>
                  <v-col cols="8">
                    <v-file-input outlined label="Oferta"> </v-file-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field prefix="$" outlined label="Precio unitario *"> </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field  outlined label="Cantidad" min="0" type="number"> </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="5">
                    <v-text-field prefix="$"  outlined label="Total oferta"> </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>
      <template #actions>
        <div>
          <v-btn color="secondary" @click="value = false" class="mr-4" outlined>
            Cerrar
          </v-btn>
        </div>
      </template>
    </AppDialog>
    <!--FIN  Modal -->
  </section>
</template>

<script>
import DataTable from "@/components/DataTableComponent.vue";
export default {
  components: {
    DataTable,
  },
  data: () => ({
    value: false,
    items: [
      {
        especificacion: "Papel bond - Celeste 1",
        obs: "OBS 1",
        proveedor: "Proveedor 1",
        cantidad: 10,
      },
      {
        especificacion: "Papel bond - Celeste 2",
        obs: "OBS 2",
        proveedor: "Proveedor 2",
        cantidad: 20,
      },
    ],
    headers: [
      {
        text: "Especificación",
        align: "start",
        sortable: false,
        value: "especificacion",
        width: "30%",
      },
      { text: "OBS", value: "obs", width: "20%" },
      { text: "Proveedor", value: "proveedor", width: "20%" },
      { text: "Cantidad", align: "center", value: "cantidad", width: "10%" },
      { text: "Acciones", align: "center", value: "actions", width: "20%" },
    ],
    actions: [
      {
        tooltip: {
          text: "Ver",
          color: "blueGrayMinsal",
        },
        icon: {
          color: "secondary",
          name: "mdi-eye",
        },
        eventName: "show",
      },
    ],

    proveedorSelect: ["Proveedor 1", "Proveedor 2"],
    obsSelect: ["Obs 1", "Obs 2"],
  }),

  methods: {
    show(item) {
      this.value = true;
    },
  },
};
</script>

<style></style>
