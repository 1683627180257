<template>
  <section>
    <v-row>
      <v-col cols="12">
        <p>
          Durante este proceso el jurado realizará las evaluaciones pertinentes
          a cada proveedor por cada uno de los OBS.
        </p>
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          @keyup="getPeticion"
          v-model="especificacion"
          label="Especificación"
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="4">
        <v-autocomplete
          @change="getEvaluacion"
          placeholder="Seleccione"
          outlined
          label="OBS"
          :items="selectObs"
          item-text="nombre"
          item-value="id"
          v-model="id_obs"
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col cols="12">
        <DataTable
          :headers="headers"
          :items="items"
          v-models:select="por_pagina"
          v-models:pagina="pagina"
          :total_registros="total_registros"
          @paginar="paginar"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon color="secondary" @click="show(item)">
              mdi-eye
            </v-icon>
          </template>
        </DataTable>
      </v-col>
    </v-row>
    <!-- Modal -->
    <AppDialog
      :showClose="false"
      v-model="value"
      title="Oferta presentada"
      :maxWidth="1000"
    >
      <template #content>
        <v-row>
          <v-col cols="12" sm="5">
            <v-row>
              <v-col cols="12">
                <span>Nombre del proveedor</span>
                <p class="font-weight-black">
                  {{ datos.ProveedorParticipacionObs[0].proveedor }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <span>OBS</span>
                <p class="font-weight-black">
                  {{ datos.ProcesoOb.obs?.nombre }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <span>Articulo</span>
                <p class="font-weight-black">{{ datos.articulo }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <span>Unidad de medida</span>
                <p class="font-weight-black">{{ datos.Presentacion?.nombre }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <span>Especificación</span>
                <p class="font-weight-black">
                  {{ datos.especificacion }}
                </p>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="7">
            <v-card elevation="5">
              <v-card-text>
                <v-card-title style="color: #404a7a"
                  >Formulario de oferta</v-card-title
                >
                <v-row>
                  <v-col cols="8">
                    <v-text-field
                      append-icon="mdi-paperclip"
                      readonly
                      label="Oferta"
                      outlined
                      :value="
                        datos.ProveedorParticipacionObs[0].nombre_documento
                      "
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      readonly
                      prefix="$"
                      outlined
                      label="Precio unitario *"
                      :value="
                        datos.ProveedorParticipacionObs[0].precio_unitario
                      "
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      outlined
                      label="Cantidad"
                      :value="datos.ProveedorParticipacionObs[0].cantidad"
                      min="0"
                      type="number"
                      readonly
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="5">
                    <v-text-field
                      readonly
                      prefix="$"
                      outlined
                      :value="datos.ProveedorParticipacionObs[0].total"
                      label="Total oferta"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>
      <template #actions>
        <div>
          <v-btn
            color="secondary"
            @click="(value = false), limpiar()"
            class="mr-4"
            outlined
          >
            Cerrar
          </v-btn>
        </div>
      </template>
    </AppDialog>
    <!--FIN  Modal -->
  </section>
</template>

<script>
import DataTable from "@/components/DataTableComponent.vue";

export default {
  name: "Evaluacion",
  components: {
    DataTable,
  },
  props: {
    idProceso: {
      type: String,
    },
  },
  data: () => ({
    //Filtros:
    especificacion: null,
    id_obs: null,
    selectObs: [],
    //Paginación:
    pagina: 1,
    por_pagina: 5,
    total_registros: 0,
    //Definición de variables que se usan para la obtención
    //de la data para el modal al dar clic en el icono de "ver":
    datosModal: [],
    datos: [],
    id_subproceso: null,
    nombreProveedorModal: "",
    articuloModal: "",
    nombreObsModal: "",
    especificarModal: "",
    urlModalPdf: "",
    precioUnitarioModal: null,
    cantidadModal: null,
    totalOfertaModal: null,
    value: false,
    //Data table:
    items: [],
    headers: [
      {
        text: "Especificación",
        align: "start",
        sortable: false,
        value: "nombre_agrupacion",
        width: "30%",
      },
      { text: "OBS", value: "nombre_ob", width: "20%" },
      { text: "Proveedor", value: "nombre_proveedor", width: "20%" },
      { text: "Cantidad", align: "center", value: "cantidad", width: "10%" },
      { text: "Acciones", align: "center", value: "actions", width: "20%" },
    ],
    actions: [
      {
        tooltip: {
          text: "Ver",
          color: "blueGrayMinsal",
        },
        icon: {
          color: "secondary",
          name: "mdi-eye",
        },
        eventName: "show",
      },
    ],
  }),

  methods: {
    async show(item) {
      this.response = item.id_subproceso;
      const resp = await this.services.Proveedores.getEvaluacionDetalle(
        this.idProceso,
        this.response,
        item.id_proveedor
      );
      if (resp.status === 200) {
        this.value = true;
        this.datosModal = resp.data;
        this.datos = this.datosModal;
      } else {
        this.temporalAlert({
          show: true,
          message: resp.data,
          type: "error",
        });
        this.value = false;
      }
    },
    async getEvaluacion() {
      let params = {
        page: this.pagina,
        per_page: this.por_pagina,
        pagination: true,
        especificacion: this.especificacion,
        id_obs: this.id_obs,
      };
      let response = await this.services.Proveedores.getEvaluaciones(
        this.idProceso,
        params
      );
      this.items = response.data.data;
      this.total_registros = response.data.count;
    },
    async getSelectObs() {
      const res = await this.services.Proveedores.getObsProveedor(
        this.idProceso
      );
      this.selectObs = res.data;
    },

    getPeticion() {
      setTimeout(() => {
        this.getEvaluacion();
      }, 1500);
    },
    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.pagina = pagina;
      this.por_pagina = cantidad_por_pagina;
      this.getEvaluacion();
    },
    limpiar() {
      this.value = false;
      this.datos.ProveedorParticipacionObs[0].proveedor = null;
      this.datos.ProcesoOb.obs.nombre = null;
      this.datos.articulo = null;
      this.datos.Presentacion.nombre = null;
      this.datos.especificacion = null;
      this.datos.ProveedorParticipacionObs[0].nombre_documento = null;
      this.datos.ProveedorParticipacionObs[0].precio_unitario = null;
      this.datos.ProveedorParticipacionObs[0].cantidad = null;
      this.datos.ProveedorParticipacionObs[0].total = null;
    },
  },

  created() {
    this.getEvaluacion();
    this.getSelectObs();
  },
};
</script>
