<template>
  <section class="backgroundColor">
    <v-row class="pt-5 pr-3">
      <v-col cols="12" sm="3">
        <p>Código</p>
        <p class="text-h5">{{ infoGeneral.codigo_proceso }}</p>
        <p>Forma de contratación</p>
        <p class="text-h5">{{ infoGeneral.nombre_forma_contratacion}}</p>
      </v-col>
      <v-col cols="12" sm="9">
        <p>Información Adicional</p>
        <p class="text-justify">
          {{ infoGeneral.justificacion_compra }}
        </p>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: "GeneralProcesoCompraComponent",
  props: ['id'],
  data: () => ({
    id_proceso: null,
  }),
  computed: {
    ...mapState("paacProveedor",[
      "infoGeneral",
    ]),
  },
  methods: {
    ...mapActions("paacProveedor", [
      "getInfoGeneral",
    ]),
  },
  async created(){
    this.id_proceso = this.id
    if(this.id_proceso !== null){
      await this.getInfoGeneral(this.id_proceso)
    }
  }
};
</script>

<style scoped>
.backgroundColor {
  background-color: #f4f7fd;
}
</style>
