import DataTableComponent from '@/components/DataTableComponent.vue';
<template>
  <section>
    <v-row class="mb-10">
      <v-col cols="12">
        <data-table-component 
          :headers="headers"
          :items="listDocumentosBase"
          :tiene_paginacion="false"
        >
          <template #[`item.fecha_carga`]="{ item }">
            {{ moment(item).format("DD/MM/YYYY") }}
          </template>
          <template v-slot:[`item.versiones`]="{ item }">
            <v-icon
              color="secondary"
              @click="descargarDocumento(item)"
            >
              mdi-download
            </v-icon>
          </template>
        </data-table-component>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import PaacServices from '@/services/Paac.services';

import { mapState } from 'vuex';
export default {
  name: "BasesDelProcesoComponent",
  components: {
    DataTableComponent,
  },
  data() {
    // Headers de la tabla, Documento, Titulo, Fecha de carga, Versiones (Tendrá un botón para descargar)
    return {
      headers: [
        { text: "Documento", value: "nombre_documento" },
        { text: "Tipo de documento", value: "tipo_documento" },
        { text: "Fecha de carga", value: "fecha_carga" },
        { text: "Versiones", value: "versiones" },
      ],
    };
  },

  computed: {
    ...mapState('etapaBaseProceso', ['listDocumentosBase'])
  },

  methods: {
    async descargarDocumento(item){
      const response = await PaacServices.downloadDocumentoProcesoCompra(item.ruta_documento)
      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });
      } else {
        const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileUrl;
        fileLink.setAttribute("download", `${item.nombre_documento}`);
        document.body.appendChild(fileLink);
        fileLink.click();
      }
    },
  }
};
</script>
<style></style>
